/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChevronRightIcon, ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import slugify from "slugify";
import Button from "~components/common/Button";
import LoadingDots from "~components/common/loadinganimations/LoadingDots";

function Form({ handleCityChange, cityDatalist, handleRequest, loading, error, errorMessage, cityInput }) {
	const handleKey = (e) => {
		if (e.keyCode === 13) {
			handleRequest();
		}
	};

	return (
		<>
			<p className="mb-8 font-bold">Berechne jetzt Deinen potenziellen Umsatz!</p>
			<div className="flex max-w-2xl border border-brand shadow md:mx-auto">
				<div className="mx-auto grid flex-1">
					<div className="relative">
						<label htmlFor="staedte" className="absolute left-0 text-xs" style={{ top: -18 }}>
							Stadt
						</label>
						<input
							type="text"
							id="staedte"
							name="staedte"
							list="staedte-data"
							value={cityInput}
							placeholder="Kassel"
							className="w-full border-0 px-3 py-4 focus:border-transparent focus:outline-none focus:ring-1 focus:ring-inset focus:ring-black focus:ring-offset-1"
							onChange={handleCityChange}
							onKeyDown={handleKey}
						/>
						{cityDatalist && (
							<datalist id="staedte-data">
								{cityDatalist.map((entry) => (
									<option key={`${slugify(entry)}`} value={entry} label={entry} />
								))}
							</datalist>
						)}
					</div>
				</div>
				<Button
					text={
						loading ? (
							<>
								<LoadingDots />
							</>
						) : (
							<>
								<span className="hidden sm:inline">Potenzial berechnen</span>
								<ChevronRightIcon className="inline-block h-4 w-4 sm:ml-2" />
							</>
						)
					}
					onClick={handleRequest}
					brand
				/>
			</div>
			{error && (
				<div className="mt-2 max-w-2xl border-l-4 border-yellow-400 bg-yellow-50 p-4 md:mx-auto">
					<div className="flex">
						<div className="shrink-0">
							<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
						</div>
						<div className="ml-3">
							<p className="text-sm text-yellow-700">
								{errorMessage
									? errorMessage
									: "Etwas ist schiefgegangen. Probiere es später wieder, oder kontaktiere uns direkt."}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Form;
