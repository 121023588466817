import { EmojiSadIcon, ExclamationIcon, PresentationChartLineIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useRef, useState } from "react";
import BlurbSphere from "~components/features/BlurbSphere";
import Button from "../common/Button";
import LoadingDots from "../common/loadinganimations/LoadingDots";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
import CheckboxCard from "~components/forms/elements/CheckboxCard";
import Datenschutz from "~components/forms/elements/Datenschutz";
import Input from "~components/forms/elements/Input";
import Wizard from "~components/forms/elements/Wizard";

const Chart = React.lazy(() => import("../sites/immobilienmakler/Chart"));

const svg = {
	morgens: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 9v-6l3 3m-6 0l3 -3" />
		</svg>
	),
	mittags: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<circle cx="12" cy="12" r="4" />
			<path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
		</svg>
	),
	abends: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="inline"
			width="64"
			height="64"
			viewBox="0 0 24 24"
			strokeWidth="1"
			stroke="currentColor"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
			<line x1="3" y1="21" x2="21" y2="21" />
			<path d="M12 3v6l3 -3m-6 0l3 3" />
		</svg>
	),
};

function formatToEur(number) {
	return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: 0 }).format(
		number
	);
}

function FormImmobilienmakler({ queryResult, cityInput, umsatzInput, handleReset }) {
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [revenue, setRevenue] = useState(umsatzInput);
	const [queryResultRevenue, setQueryResultRevenue] = useState(null);

	const formDiv = useRef(null);

	const isSSR = typeof window === "undefined";

	const prevStep = () => {
		setStep((prevCount) => prevCount - 1);
		formDiv.current.scrollIntoView();
	};

	const nexStep = () => {
		setStep((prevCount) => prevCount + 1);
		formDiv.current.scrollIntoView();
	};

	const queryRevenue = async () => {
		setError(false);
		setLoading(true);
		const body = {
			keywordVolume: queryResult.data.keywordVolume,
			revenue,
			competitionFactor: queryResult.data.competitionFactor,
		};
		try {
			const response = await axios.post(`/api/keyword-research-revenue`, body);
			setQueryResultRevenue(response);
			setLoading(false);
			nexStep();
		} catch (err) {
			setLoading(false);
			setError(true);
			if (err.response.data) {
				setErrorMessage(err.response.data);
			}
			console.log(err);
		}
	};

	const validateRevenue = (e) => {
		e.preventDefault();
		const input = document.getElementById("revenueData");
		let error = false;
		if (input.checkValidity() === false) {
			input.reportValidity();
			error = true;
		}
		if (!error) {
			queryRevenue();
		}
	};

	const validateMaindata = (e) => {
		e.preventDefault();
		const inputs = document.getElementById("mainData").querySelectorAll("input");
		const errors = [];
		inputs.forEach((input) => {
			if (input.checkValidity() === false) {
				input.reportValidity();
				errors.push("Error");
			}
		});
		if (!errors.length > 0) {
			nexStep();
		}
	};

	const resetSteps = () => {
		setStep(0);
		handleReset();
	};

	return (
		<form
			name="Immobilienmakler"
			method="POST"
			data-netlify="true"
			action="/anfrage-gesendet-immobilienmakler/"
			className="space-y-4"
			ref={formDiv}
		>
			<input type="hidden" name="form-name" value="Immobilienmakler" />
			<input
				type="hidden"
				name="Suchvolumen"
				value={queryResult && queryResult.data && queryResult.data.keywordVolume ? queryResult.data.keywordVolume : ""}
			/>
			<input type="hidden" name="Keyword" value={cityInput ? `Immobilienmakler ${cityInput}` : ""} />
			<input
				type="hidden"
				name="Konkurenz"
				value={queryResult && queryResult.data && queryResult.data.competition ? queryResult.data.competition : ""}
			/>
			<input
				type="hidden"
				name="Konkurenz Faktor"
				value={
					queryResult && queryResult.data && queryResult.data.competitionFactor
						? queryResult.data.competitionFactor
						: ""
				}
			/>
			<input type="hidden" name="Umsatz pro Kunde" value={revenue ? formatToEur(revenue) : ""} />
			<input
				type="hidden"
				name="Umsatz pro Monat"
				value={
					queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.monthlyRevenue
						? formatToEur(queryResultRevenue.data.monthlyRevenue)
						: ""
				}
			/>
			<input
				type="hidden"
				name="Kosten Optimierung"
				value={
					queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.optimizationCost
						? formatToEur(queryResultRevenue.data.optimizationCost)
						: ""
				}
			/>
			<input
				type="hidden"
				name="Kosten Website einmalig"
				value={
					queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.costWebsite
						? formatToEur(queryResultRevenue.data.costWebsite)
						: ""
				}
			/>
			<input
				type="hidden"
				name="Kosten Website monatlich"
				value={
					queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.monthlyCost
						? formatToEur(queryResultRevenue.data.monthlyCost)
						: ""
				}
			/>
			<input
				type="hidden"
				name="Return on Invest Monat"
				value={
					queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.returnOnInvestMonth
						? queryResultRevenue.data.returnOnInvestMonth
						: ""
				}
			/>

			<Wizard currentStep={step}>
				<div className="space-y-4">
					{queryResult && queryResult.data && queryResult.data.keywordVolume >= 10 ? (
						<>
							<BlurbSphere
								number={queryResult.data.keywordVolume}
								title="Suchvolumen"
								titleAs="p"
								text={
									<>
										{queryResult.data.keywordVolume} mal wird <strong>Immobilienmakler {cityInput}</strong> jeden Monat
										bei Google gesucht. Die Konkurenz zu diesem Keyword ist {queryResult.data.competition}. Anhand der
										Konkurenz berechnen wir die Optimierungskosten.
									</>
								}
								svg={<PresentationChartLineIcon />}
								numberClass="flex items-center justify-center w-24 h-24 text-white bg-black rounded-full text-2xl"
							/>
							<div className="mt-0 ">
								<P className="mb-2">
									Wie hoch ist ca. Dein Umsatz <strong>pro Kunde</strong> in Euro?
								</P>
							</div>
							<Input
								type="text"
								label="Dein Umsatz pro Kunde"
								name="Dein Umsatz pro Kunde"
								value={revenue}
								id="revenueData"
								onChange={(e) => setRevenue(e.target.value)}
								placeholder="1000"
								inlineLabel="€"
								classNameContainer="max-w-sm"
							/>
							{error && (
								<div className="mt-2 border-l-4 border-yellow-400 bg-yellow-50 p-4">
									<div className="flex">
										<div className="shrink-0">
											<ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
										</div>
										<div className="ml-3">
											<p className="text-sm text-yellow-700">
												{errorMessage
													? errorMessage
													: "Etwas ist schiefgegangen. Probiere es später wieder, oder kontaktiere uns direkt."}
											</p>
										</div>
									</div>
								</div>
							)}
							<div className="">
								<Button
									onClick={(e) => validateRevenue(e)}
									text={
										loading ? (
											<>
												<LoadingDots />
											</>
										) : (
											<>Return on Invest berechnen</>
										)
									}
									className="w-full"
									brand
								/>
							</div>
						</>
					) : (
						<div>
							<div className="text-center">
								<EmojiSadIcon className="mx-auto mb-3 h-24 w-24" />
								<P className="mb-3">
									Leider gibt es kein Suchvolumen für <span className="font-bold">Immobilienmakler {cityInput}</span>.
									Probiere es gerne mit einem anderen Suchbegriff.
								</P>
							</div>
							<div className="grid">
								<Button onClick={resetSteps} text="Neues Keyword probieren" className="col-span-2 w-full" />
							</div>
						</div>
					)}
				</div>
				<div>
					{queryResult && queryResultRevenue && queryResultRevenue.status === 202 && (
						<div className="text-center">
							<EmojiSadIcon className="mx-auto mb-3 h-24 w-24" />
							<P className="mb-3">
								Leider übersteigen die Kosten für das Suchwort <strong>Immobilienmakler {cityInput}</strong> Deinen
								Gewinn. Probiere es gerne mit einem anderen Suchbegriff.
							</P>
							<div className="my-3 overflow-hidden border border-gray-200">
								<table className="min-w-full divide-y divide-gray-200">
									<tbody className="divide-y divide-gray-200 bg-white">
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-left text-sm font-medium text-gray-900">
												Umsatz pro Monat
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.monthlyRevenue)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="my-3 overflow-hidden border border-gray-200">
								<table className="min-w-full divide-y divide-gray-200">
									<tbody className="divide-y divide-gray-200 bg-white">
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-left text-sm font-medium text-gray-900">
												Kosten Optimierung
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												einmalig {formatToEur(queryResultRevenue.data.optimizationCost)}
											</td>
										</tr>
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-left text-sm font-medium text-gray-900">
												Kosten Website einmalig
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.costWebsite)}
											</td>
										</tr>
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-left text-sm font-medium text-gray-900">
												Kosten Website monatlich
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.monthlyCost)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					)}
					{queryResult && queryResultRevenue && queryResultRevenue.data && queryResultRevenue.data.chartData && (
						<>
							<div className="mt-0 ">
								<H3 className="mb-3 font-bold">Voraussichtlicher Gewinn und Kosten</H3>
							</div>
							<div style={{ height: 300 }} className="mb-5">
								{!isSSR && (
									<React.Suspense fallback={null}>
										<Chart data={queryResultRevenue.data.chartData} />
									</React.Suspense>
								)}
							</div>
							<P>
								Wenn nur {queryResultRevenue.data.conversionFactor * 100} % der {queryResult.data.keywordVolume}{" "}
								Suchenden mit dem Suchwort <strong>Immobilienmakler {cityInput}</strong> auf Deiner Webseite landen und
								Dich beauftragen, dann kannst Du mit unserem System ca.{" "}
								<strong>{formatToEur(queryResultRevenue.data.monthlyRevenue)} pro Monat</strong> verdienen. Du hast die
								Kosten ab dem {queryResultRevenue.data.returnOnInvestMonth}. Monat wieder raus.
							</P>
							<div className="my-3 overflow-hidden border border-gray-200">
								<table className="min-w-full divide-y divide-gray-200">
									<tbody className="divide-y divide-gray-200 bg-white">
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
												Umsatz pro Monat
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.monthlyRevenue)}
											</td>
										</tr>
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
												Return on Invest
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												ab Monat {queryResultRevenue.data.returnOnInvestMonth}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<P>
								Die Optimierung auf das Suchwort kostet Dich <strong>einmalig</strong>{" "}
								{formatToEur(queryResultRevenue.data.optimizationCost)}. Für die Website berechnen wir einmalig{" "}
								{formatToEur(queryResultRevenue.data.costWebsite)} und monatlich{" "}
								{formatToEur(queryResultRevenue.data.monthlyCost)}.
							</P>
							<div className="my-3 overflow-hidden border border-gray-200">
								<table className="min-w-full divide-y divide-gray-200">
									<tbody className="divide-y divide-gray-200 bg-white">
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
												Kosten Optimierung
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												einmalig {formatToEur(queryResultRevenue.data.optimizationCost)}*
											</td>
										</tr>
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
												Kosten Website einmalig
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.costWebsite)}
											</td>
										</tr>
										<tr>
											<td className="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
												Kosten Website monatlich
											</td>
											<td className="whitespace-nowrap px-2 py-4 text-right text-sm text-gray-500">
												{formatToEur(queryResultRevenue.data.monthlyCost)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p className="mb-5 text-sm italic text-gray-700">
								Die Optimierungskosten und der Umsatz sind eine Schätzung. Ein konkretes Angebot erhältst Du nach Deiner
								Anfrage. Ein monatliches Reporting, gibt Dir Aufschluss, wo Du bei Google stehst. Alle Preise exkl.
								MwSt.
							</p>
						</>
					)}
					<div className="grid grid-cols-2 gap-3">
						{queryResultRevenue && queryResultRevenue.status === 202 ? (
							<>
								<Button
									onClick={(e) => prevStep(e)}
									text="Umsatz pro Kunden ändern"
									className="col-span-2 w-full"
									white
								/>
								<Button onClick={resetSteps} text="Neues Keyword probieren" className="col-span-2 w-full" />
							</>
						) : (
							<>
								<Button onClick={(e) => prevStep(e)} text="Umsatz ändern" className="w-full" white />
								<Button onClick={(e) => nexStep(e)} text="Jetzt anfragen" className="w-full" brand />
							</>
						)}
					</div>
				</div>
				<div className="space-y-4" id="mainData">
					<div className="mt-0 ">
						<H3 className="mb-2 font-bold">Kontaktdaten</H3>
						<P>Diese Informationen sind wichtig, damit wir Dich kontaktieren können.</P>
					</div>
					<Input
						type="text"
						label="Firma"
						id="firma"
						name="Firma"
						placeholder="Dein Unternehmensname"
						autoComplete="organization"
					/>

					<div className="grid-cols-2 gap-4 sm:grid ">
						<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
						<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />
					</div>
					<Input
						type="tel"
						label="Telefon"
						id="telefon"
						name="Telefon"
						placeholder="Deine Telefonnummer"
						autoComplete="tel"
						inputMode="numeric"
						required
					/>
					<Input
						type="email"
						label="E-Mail"
						id="email"
						name="E-Mail"
						placeholder="Deine E-Mailadresse"
						autoComplete="email"
						required
					/>
					<div className="grid grid-cols-2 gap-3">
						<Button onClick={(e) => prevStep(e)} text="Zurück" className="w-full" white />
						<Button onClick={(e) => validateMaindata(e)} text="Weiter" className="col-start-2 w-full" brand />
					</div>
				</div>
				<div>
					<div className="my-4">
						<H3 className="mb-2">Wann bist Du erreichbar?</H3>
						<P>Diese Informationen sind wichtig, damit wir Dich nicht zu unpassenden Zeiten kontaktieren.</P>
					</div>
					<div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
						<CheckboxCard
							label="Morgens"
							name="Erreichbar-Morgens"
							value="Ja"
							id="erreichbarkeit-morgens"
							svg={svg.morgens}
						/>

						<CheckboxCard
							label="Mittags"
							name="Erreichbar-Mittags"
							value="Ja"
							id="erreichbarkeit-mittags"
							svg={svg.mittags}
						/>

						<CheckboxCard
							label="Abends"
							name="Erreichbar-Abends"
							value="Ja"
							id="erreichbarkeit-abends"
							svg={svg.abends}
						/>
					</div>
					<div className="mb-3 grid grid-cols-2 gap-3">
						<Button onClick={(e) => prevStep(e)} text="Zurück" className="w-full" white />
						<Button submit text="Anfrage senden" className="w-full" brand />
					</div>
					<Datenschutz />
				</div>
			</Wizard>
		</form>
	);
}

export default FormImmobilienmakler;
