import React from "react";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

interface Props {
	title: string;
	svg: React.ComponentProps<"svg">;
	number?: string;
	titleAs?: string;
	text?: string;
	children?: string;
	className?: string;
	numberClass?: string;
}

const BlurbSphere: React.FC<Props> = ({ number, title, titleAs, text, svg, children, className, numberClass }) => {
	return (
		<div className={`relative space-y-3 ${className ? className : ""}`}>
			<div className="absolute w-16 right-8 lg:w-32" style={{ top: 50 }}>
				<div className="p-3 text-white bg-black rounded-full select-none bg-opacity-30 opacity-10 md:p-6">{svg}</div>
			</div>
			{number && (
				<div
					className={`${
						numberClass ? numberClass : "flex h-12 w-12 items-center justify-center rounded-full bg-black text-white"
					}`}
				>
					{number}
				</div>
			)}
			<H3 as={titleAs} className="text-lg font-display md:text-xl">
				{title}
			</H3>
			{children ? <div>{children}</div> : <P>{text}</P>}
		</div>
	);
};

export default BlurbSphere;
