import { Transition } from "@headlessui/react";
import {
	ArrowCircleLeftIcon,
	CalendarIcon,
	ChartBarIcon,
	CheckCircleIcon,
	CodeIcon,
	CurrencyEuroIcon,
	LocationMarkerIcon,
	PaperClipIcon,
	PhotographIcon,
	ScaleIcon,
	ShieldCheckIcon,
	SwitchHorizontalIcon,
	TemplateIcon,
	UserGroupIcon,
} from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import axios from "axios";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import BlurbInline from "~components/features/BlurbInline";
import BlurbSphere from "~components/features/BlurbSphere";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Button from "~components/common/Button";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import LandingpageHeader from "~components/sections/landingpages/LandingpageHeader";
import FormImmobilienmakler from "~components/forms/FormImmobilienmakler";
import Layout from "~components/layouts/Layout";
import Footer from "~components/layouts/Footer";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import CtaSection from "~components/sections/Cta";
import Logos from "~components/sites/immobilienmakler/Logos";
import Search from "~components/sites/immobilienmakler/Search";
import H2 from "~components/typography/H2";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import { cityData } from "../../../data/cityData";

export default function Page({ data, location }) {
	const keyword = "Immobilienmakler Website";
	const title = "Immobilienmakler Website: Jetzt auf Google Seite 1 ↗️";
	const description =
		"Mit einer Immobilienmakler Website Deinen Umsatz erhöhen! Mit Google Seite 1 TOP 10 Garantie automatisch Kundenanfragen generieren.";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.headerImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.headerImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.headerImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<SchemaProduct
				name={title}
				image={`${data.site.siteMetadata.siteUrl}${data.headerImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				description={description}
				url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
				lowPrice="500"
				highPrice="5000"
				aggregateOffer
			/>
			<LandingpageHeader
				headline={{
					one: "Immobilienmakler Website",
					two: "Erhöhe Deinen Umsatz mit einer Website auf Google Seite 1",
				}}
				image={{ data: data.headerImage.header, alt: `${keyword} - Arzt im Portrait` }}
				text={
					<>
						Erhöhe Deine Sichtbarkeit bei Google und erhöhe damit Deinen Umsatz als Immobilienmakler! Wir programmieren
						Dir eine top Immobilienmakler Website und kümmern uns, dass diese bei Google erscheint. So gewinnst Du
						ständig ganz <strong>automatisch Kunden</strong> und erhöhst Deinen Umsatz. Um unsere Qualität zu sichern,
						nehmen wir nur <strong>einen Immobilienmakler pro Stadt</strong> an.
					</>
				}
				buttonOne={{ text: "Anfragen", to: `${location.pathname}anfragen/` }}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
			/>

			<Container noPadding className="my-8 md:my-32">
				<H2 className="mb-5 font-display md:text-center" display>
					Was wir konkret machen:
				</H2>
				<Grid cols={3}>
					<BlurbSphere number="1" title="Website-Entwicklung" titleAs="p" svg={<CodeIcon />}>
						<P>
							Deine Webseite muss bestimmte Voraussetzungen erfüllen. Um diesen gerecht zu werden, bauen wir für Dich
							eine neue Website, die auch bei Google unter den Top-Webseiten mitmischen kann.{" "}
							<AnchorLink
								to={`${location.pathname}#website`}
								className="border-b border-gray-700 border-dotted hover:border-solid"
							>
								Mehr erfahren
							</AnchorLink>
						</P>
					</BlurbSphere>
					<BlurbSphere number="2" title="Optimierung" titleAs="p" svg={<TemplateIcon />}>
						<P>
							Wir erstellen themenrelevanten Content, strukturieren die Website und gewinnen Backlinks für Deine neue
							Website. So schaffen wir es, dass Google Deine Webseite positiv bewertet und in den Ergebnissen anzeigt.{" "}
							<AnchorLink
								to={`${location.pathname}#onpage`}
								className="border-b border-gray-700 border-dotted hover:border-solid"
							>
								Mehr erfahren
							</AnchorLink>
						</P>
					</BlurbSphere>
					<BlurbSphere number="3" title="Analyse und Skalierung" titleAs="p" svg={<ChartBarIcon />}>
						<P>
							Sobald Du für ein Keyword auf der ersten Seite rankst, können wir andere Keywords angreifen. So können wir
							Dir weiteren Wachstum für Deine Homepage und und Dein Business garantieren.
						</P>
					</BlurbSphere>
				</Grid>
			</Container>
			<Container id="garantie">
				<div className="relative items-stretch bg-pattern md:grid md:grid-cols-2">
					<div className="relative p-4 sm:p-8 md:order-2 md:py-12">
						<H2 className="font-bold font-display">Geld-zurück-Garantie</H2>
						<P className="mb-5 font-bold">Dein Erfolg ist unser Erfolg</P>
						<P className="mb-3 font-bold">
							Du erhältst von uns die einmaligen Kosten für die SEO-Optimierung zurück, wenn das vereinbarte Suchwort
							nicht auf der ersten Seite innerhalb 6 Monaten bei Google angezeigt wurde. Allerdings ranken die Websites
							innerhalb 6 - 12 Wochen nach online Schaltung der Website.
						</P>
						<P className="mb-5">
							Aufgrund unserer jahrelangen Erfahrung in den unterschiedlichsten Branchen und Keywords, können wir durch
							modernste Analyse-Tools feststellen, ob das Projekt bevor wir es annehmen durchgeführt werden kann. Alle
							Keywords werden auf Konkurrenz und Suchvolumen geprüft, sodass wir bei einer Auftragsannahme eine
							Erfolgsgarantie abgeben können. Darüber hinaus, ist unser Partner, die SEO Premium Agentur am 8.6.2021
							durch den Bundesverband Digitale Wirtschaft (BVDW) e. V. geprüft und mit dem Fachkräftezertifikat
							ausgezeichnet worden.
						</P>
						<Button anchor={`${location.pathname}#search`} text="Jetzt Potential berechnen" className="mt-5" />
					</div>
					<div className="relative object-cover w-full h-full bg-brand" style={{ minHeight: 200 }}>
						<GatsbyImage
							image={getImage(data.imageGarantie)}
							alt="Immobilienmakler Website | Garantie"
							title="Immobilienmakler Website | Garantie"
							className="absolute inset-0 object-cover w-full h-full align-top bg-brand"
						/>
					</div>
				</div>
			</Container>
			<Container id="website">
				<Grid cols={2}>
					<div className="md:order-2">
						<Divider className="mb-4" />
						<NumberedSection text="Der Grundbaustein" number="01" className="mb-5" />
						<H2 className="mb-5 font-display">Website-Entwicklung</H2>
						<P className="mb-5">
							Eine technisch saubere Immobilienmakler Website, die sowohl Design, Nutzererfahrung, Geschwindigkeit und
							Flexibilität vereint, ist für Google essenziell. Landet ein Benutzer nun auf Deiner Seite, ist es zudem
							wichtig diesen richtig abzuholen. Möchte er seine Immobilie kaufen, verkaufen oder vermieten? Wir holen
							den Besucher auf Deiner Homepage sorgfältig ab, sodass er bei Dir eine Anfrage stellt. Das bedeutet für
							uns: Wir erstellen Dir Deine komplett individuelle und auf Kundenanfragen optimierte Website. Wir halten
							es einfach: Die technische Grundlage wie Hosting, Domain, Responsive Webdesign und vieles mehr ist bei uns
							inberiffen. Du erhälst eine echte Komplettlösung und kannst Dich an Deiner neuen Homepage - Dein neuer
							Leadgenerator - erfreuen.
						</P>
						<div className="mb-5 space-y-5">
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="SEO optimiert"
								as="p"
								text="Wir erstellen passend zu der Suchmaschinenoptimierung eine neue Immobilienmakler Website, die hinsichtlich OnPage-Optimierung und Performance alles übertrifft, was Du vorher gesehen hast."
							/>
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Für Leadanfragen optimiert"
								as="p"
								text="Eine einfache Website reicht nicht. Diese muss auch den Besucher zum Kaufen animieren. Mit unseren erprobten Landingpages, haben wir die richtigen Richtlinien, die zum Erfolg führen. So steigern wir die Konversionsrate auf ein Maximum."
							/>
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Individuelles Webdesign und Layout"
								as="p"
								text="Alle unsere Websites sind Einzelstücke. Wir konzeptionieren und designen jedes Projekt individuell."
							/>
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Full-Service"
								as="p"
								text="Unsere Websites stehen für eine kleine monatliche Gebühr zur Verfügung. Während der Laufzeit sind alle Änderungen, Hosting und vieles mehr inklusive."
							/>
						</div>
						<div className="relative p-3 bg-pattern">
							<figure className="p-3 bg-white">
								<div className="mb-3">
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
								</div>
								<blockquote className="mb-3">
									Andy betreut mich und meine Projekte jetzt schon seit mehreren Jahren. Immer wieder besticht er durch
									seine Kreativität, Umsetzungsschnelligkeit und Innovation was CMS etc anbelangt. Meine Partner und ich
									sind sehr zufrieden und empfehlen Andy uneingeschränkt weiter.
								</blockquote>
								<figcaption className="flex items-center justify-end">
									<span className="hidden sm:inline">
										07.02.2021
										{" • "}
									</span>
									<cite className="flex items-center ml-1 font-bold">
										Johannes Förster{" "}
										<GatsbyImage
											image={getImage(data.imageTestmonialJohannes)}
											alt="Immobilienmakler Website | Testmonial Johannes Förster"
											title="Immobilienmakler Website | Testmonial Johannes Förste"
											className="ml-3 rounded-full"
										/>
									</cite>
								</figcaption>
							</figure>
						</div>
					</div>
					<div className="p-3 bg-pattern">
						<GatsbyImage
							image={getImage(data.imageWebsite)}
							alt="Immobilienmakler Website | Website Entwicklung"
							title="Immobilienmakler Website | Website Entwicklung"
						/>
					</div>
				</Grid>
			</Container>
			<Container id="onpage">
				<Grid cols={2}>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Sichtbar werden" number="02" className="mb-5" />
						<H2 className="mb-5 font-display">SEO Optimierung</H2>
						<P className="mb-5">
							Neben einer guten Website sind Backlinks einer der wichtigsten Faktoren für ein gutes
							Suchmaschinenranking. Backlinks sind Verlinkungen von externen Seiten und werden von Google als eine
							Empfehlung gewertet. Mit unserem erfahrenen Team sorgen wir dafür, dass andere Websites auf Deine neue
							Website zeigen. So landest Du in kürzester Zeit bei Google ganz weit vorne.
						</P>
						<div className="mb-5 space-y-5">
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Webseiten-Netzwerk"
								as="p"
								text="Wir nutzen unser eigenes Webseiten-Netzwerk, um Dir starke Backlinks zu generieren. So können wir Dir eine Vielzahl an Backlinks garantieren."
							/>
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Generierung von hochwertigen Backlinks"
								as="p"
								text="Wir verfügen zudem über ein Netzwerk an Seitenbetreibern, mit denen wir regelmäßig im Austausch sind. So können wir auch dort Backlinks in themenrelevanten Gebieten für Dich setzten."
							/>
							<BlurbInline
								svg={<CheckCircleIcon className="w-6 h-6" />}
								title="Andauernde Optimierung"
								as="p"
								text="Wir versprechen Dir einen monatlichen Umsatz. Damit das auch so bleibt, haben wir jeden Monat die Auswertungsdaten vor Augen und optimieren immer weiter."
							/>
						</div>
						<div className="relative p-3 bg-pattern">
							<figure className="p-3 bg-white">
								<div className="mb-3">
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
									<StarIcon className="inline-block w-4 h-4 text-yellow-300" />
								</div>
								<blockquote className="mb-3">
									Herr Lautenschlager ist ein Experte im Bereich Onlinemarketing wie man Ihn sich wünscht. Gezielt nimmt
									er sich Zeit, geht auf die Kundenwünsche ein und macht aus einem &quot;Onlinelaien&quot; einen
									&quot;Onlineprofi&quot;. Hier trifft Professionalität auf Kreativität. Wer den richtigen
									Ansprechpartner für einen starken Onlineauftritt sucht, ist hier definitiv an der richtigen Adresse!
								</blockquote>

								<figcaption className="flex items-center justify-end">
									<span className="hidden sm:inline">
										05.10.2020
										{" • "}
									</span>
									<cite className="flex items-center ml-1 font-bold">
										Florian Dierda{" "}
										<GatsbyImage
											image={getImage(data.imageTestmonialFlorian)}
											alt="Immobilienmakler Website | Testmonial Florian Dierda"
											title="Immobilienmakler Website | Testmonial Florian Dierda"
											className="ml-3 rounded-full"
										/>
									</cite>
								</figcaption>
							</figure>
						</div>
					</div>
					<div className="p-3 bg-pattern">
						<GatsbyImage
							image={getImage(data.imageOnPage)}
							alt="Immobilienmakler Website | OnPage Optimierung"
							title="Immobilienmakler Website | OnPage Optimierung"
						/>
					</div>
				</Grid>
			</Container>

			<CtaSection
				title="Immobilienmakler Website: Errechne Dein Potential"
				titleAs="p"
				text="Erhöhe Deine Sichtbarkeit bei Google und erhöhe damit Deinen Umsatz als Immobilienmakler! Wir programmieren Dir eine top Immobilienmakler Website und kümmern uns, dass diese bei Google erscheint."
				button="Optimierungskosten berechnen"
				anchor={`${location.pathname}#search`}
			/>

			<Container id="konzept">
				<Grid cols={2} className="mb-8 md:mb-32" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Von A bis Z" number="03" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Die richtige Lösung für die Bedürfnisse jedes Immobilienmaklers
						</H2>
						<P>
							Unsere Websites gehen einen Schritt weiter, als die normale Standard Homepage. Wir bauen keine statischen
							und starren Online-Broschüren, sondern Applikationen, die Dir und dem Business rund um Deine Immobilien
							weiterhelfen. Unsere speziellen Lösungen für <strong>Immobilienmakler Websites</strong> sind{" "}
							<u>optional</u> verfügbar und können je nach Bedarf eingefügt werden. So hast Du die maximale
							Flexibilität. Hinzu kommen eine Vielzahl an zusätzlichen Agentur-Leistungen, sodass das perfekte Auftreten
							vor dem Kunden garantiert ist.
						</P>
					</div>
				</Grid>
				<Grid cols={3}>
					<BlurbSphere title="Terminplanung mit Google Calendar" titleAs="p" svg={<CalendarIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white rounded bg-brand">Inklusive</span>
							<P>
								Eine einfache E-Mail-Adresse in Deinem Postfach reicht Dir nicht aus? Mit unserem Terminplanungstool,
								können wir auf Deinen Google Calendar zugreifen und dem Besucher Deiner Website einen Terminvorschlag
								machen, den er direkt online buchen kann. Der vom Kunden gebuchte Termin wird so automatisch in Deinem
								Google Calendar angezeigt und gilt als fest gebucht. So sparst Du Dir Absprachen mit dem Kunden und
								vermeidest Doppelbuchungen.
							</P>
						</>
					</BlurbSphere>
					<BlurbSphere title="Google Maps-Eintrag mit Google MyBusiness" titleAs="p" svg={<LocationMarkerIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white rounded bg-brand">Inklusive</span>

							<P>
								Damit Du der Platzhirsch in Deiner Stadt wirst und bleibst, empfehlen wir Dir einen Google MyBusiness
								Eintrag. Dieser Eintrag hilft Dir dabei Dich professionell mit einem Profil bei Google Maps zu
								platzieren. Mit diesem Profil kannst Du ebenfalls Kundenstimmen einholen und erhöhst zudem Deine
								Sichtbarkeit im Netz. Wir können zusätzlich diese Kundenstimmen auch per Schnittstelle in Deine Website
								einbauen.
							</P>
						</>
					</BlurbSphere>
					<BlurbSphere title="Rechtssicher in Zeiten von DSVGO und GEG" titleAs="p" svg={<ScaleIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white rounded bg-brand">Inklusive</span>

							<P>
								Zusammen mit unseren Rechtsanwälten haben wir fertige Vorlagen für eine rechtssichere DSGVO geschaffen,
								die Deine Website vor Datenschutzverstößen absichert. Zusätzlich achten wir auf das neue
								Gebäudeenergiegesetz (GEG), sodass alle Deine Immobilien Makler Bedürfnisse geklärt sind.
							</P>
						</>
					</BlurbSphere>
					<BlurbSphere title="Fertige Texte und Bilder" titleAs="p" svg={<PhotographIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white rounded bg-brand">Inklusive</span>

							<P>
								Der Time-to-Market ist uns extrem wichtig, da Du möglichst früh Deinen Umsatz erhöhen sollst. Daher
								bieten wir Grundbausteine von professionellen Textern, Grafikern und Fotografen an, auf die wir
								zurückgreifen können, um Deine Website zu bauen. Die Grundbausteine werden auf Dich zugeschnitten und
								individualisiert und danach in die Website eingebaut. So können wir relativ schnell mit der Website
								online gehen und mit der Optimierung bei Google beginnen. Alle Texte und Bilder können natürlich
								jederzeit angepasst werden und an Deine neuen Bedürfnisse und Marktsituationen angepasst werden.
							</P>
						</>
					</BlurbSphere>
					<BlurbSphere title="Schnittstelle zu Makler-Software" titleAs="p" svg={<SwitchHorizontalIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white bg-black rounded">Individuell auf Anfrage</span>
							<P>
								Du nutzt mittlerweile eine etablierte Makler-Software? Sehr gut! Unsere Software-Lösung bietet eine
								offene Schnittstelle für Makler Software an. So können wir mit der Makler-Software kommunizieren und
								individuelle Lösungen auf Deiner Website anbieten, ohne dass Du alles doppelt pflegen musst.
								Kundenanfragen könnten so automatisch in die Software einlaufen, aber auch alle Exposés könnten wir
								direkt aus der Software ziehen und auf Deiner Website vermarkten. Die Lösungsansätze können wir ganz
								individuell auf Deine Bedürfnisse und an die Systemvoraussetzungen der Makler-Software anpassen.
							</P>
						</>
					</BlurbSphere>
					<BlurbSphere title="Geschäftsausstattung" titleAs="p" svg={<PaperClipIcon />}>
						<>
							<span className="px-2 py-1 text-xs text-white bg-black rounded">Extra buchbar</span>

							<P className="mb-3">
								Deine neue professionelle Immobilien Homepage können wir Dir garantieren. Damit Du aber auch
								professionell Offline beim Kunden auftreten kannst, bieten wir passend dazu die perfekte Lösung an. Für
								einen kleinen einmaligen Preis bieten wir Dir eine komplette Geschäftsausstattung an:
							</P>
							<ul className="pl-3 mb-3 space-y-3 list-disc list-outside">
								<li>Professionelles Logo im Wert von 1080,- €</li>
								<li>
									10 Mark Twain Kugelschreiber in Acrylbox mit Logo-Gravur für Dich und Deine Premium-Kunden im Wert von
									200,- €
								</li>
								<li>500 individuelle Visitenkarten mit Deinem Logo im Wert von 150,- €</li>
								<li>25 Schreibblöcke mit Deinem Logo im Wert von 100,- €</li>
								<li>5 Schreibblöcke mit Deinem Logo im Wert von 100,- €</li>
							</ul>
							<P>Alle Druckprodukte können nach Verbrauch jeder Zeit nachbestellt werden.</P>
						</>
					</BlurbSphere>
				</Grid>
			</Container>

			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Kostentransparenz" number="04" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Ist SEO lohnenswert?
				</H2>
				<Grid cols={2}>
					<div>
						<P className="mb-3">
							Im Suchmaschinen-Marketing ist ein Erfolg über Nacht unwahrscheinlich. In der Regel dauert es{" "}
							<strong>sechs bis zwölf Monate</strong>, bis eine Website in den Rankings auftaucht und Deine Immobilien
							Homepage größere Besucherzahlen erhält. Das macht es schwierig für Immobilienmakler zu spüren, ob sich SEO
							lohnt, da die Veränderungen nicht sofort zusehen sind. Wenn man jedoch berücksichtigt, dass die
							Platzierung der Domain und die dazugehörige Homepage bei Google beständig ist, wird klar, dass sich diese
							Investition in Bezug auf die Kundenakquisitionskosten auf lange Hinsicht auszahlt. Solltest Du in den
							Rankings wieder nach hinten fallen, so haben wir das im Blick und helfen Dir wieder nach vorne. Berechne
							jetzt die Optimierungskosten und siehe, wann Du mit dem ersten Umsatz rechnen kannst.
						</P>
						<Button anchor={`${location.pathname}#search`} text="Optimierungskosten berechnen" />
					</div>
					<div>
						<div className="p-6 my-3 bg-pattern">
							<GatsbyImage
								image={getImage(data.imageSeoVergleich)}
								alt="Immobilienmakler Website | SEO vs klassische Werbekampagne"
								title="Immobilienmakler Website | SEO vs klassische Werbekampagne"
							/>
						</div>
					</div>
				</Grid>
			</Container>
			<Container>
				<Grid cols={2} itemsStart className="mb-8">
					<div>
						<H2 className="mb-5 font-display" display>
							Wir bringen Dein Immobilienmakler Business auf das nächste Level
						</H2>
						<P>
							Eine einfache Website reicht nicht. Sie muss zünden und ein Return on Investment bieten, das ist unser
							Credo. Wir sind so stark von unserer Dienstleistung überzeugt, dass wir jedem Kunden eine{" "}
							<strong>Geld-zurück-Garantie</strong> anbieten. Wir erhoffen uns dadurch Dein Vertrauen zu gewinnen und
							Dir zu zeigen, dass wir unser Versprechen einhalten können.
						</P>
					</div>
				</Grid>

				<Grid cols={3} itemsStart className="my-16 lg:my-32">
					<BlurbInline
						svg={<CheckCircleIcon className="w-6 h-6" />}
						title="1000 verschiedene Keywords"
						as="p"
						text="Gemeinsam mit der SEO Premium Agentur haben wir mittlerweile über 1000 verschiedene Keywords optimiert und erfolgreich bei Google platziert."
					/>
					<BlurbInline
						svg={<CheckCircleIcon className="w-6 h-6" />}
						title="300 unterschiedliche Webseiten"
						as="p"
						text="Wir haben mittlerweile über 300 verschiedene Webseiten gebaut und bringen daher ein großes Repertoire an Webdesign-Erfahrung mit."
					/>
					<BlurbInline
						svg={<CheckCircleIcon className="w-6 h-6" />}
						title="2 Wochen bis zum Projektstart"
						as="p"
						text="Der Time-to-Market spielt eine wichtige Rolle für die Suchmaschine. Deswegen können wir Deine Website mit Deiner Zuarbeit innerhalb 2 Wochen online schalten."
					/>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		headerImage: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_header.jpg" }
		) {
			header: childImageSharp {
				gatsbyImageData
			}
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}

		imageTestmonialJohannes: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_testmonial-johannes.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageTestmonialFlorian: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_testmonial-florian.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageSeoVergleich: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_seo-vs-sea.png" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageOffPage: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_off-page.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageOnPage: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_on-page.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageWebsite: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_website-entwicklung.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageGarantie: file(
			relativePath: { eq: "pages/leistungen/immobilienmakler-website/immobilienmakler-website_garantie.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
