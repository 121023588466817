import PropTypes from "prop-types";
import React from "react";
import P from "~components/typography/P";

function BlurbInline({ svg, title, text, as }) {
	let Tag = "h3";
	if (as) {
		Tag = as;
	}

	return (
		<div className="flex">
			<div className="mr-3">{svg}</div>
			<div className="flex-1">
				<Tag className="mb-3 font-bold">{title}</Tag>
				<P>{text}</P>
			</div>
		</div>
	);
}

export default BlurbInline;

BlurbInline.propTypes = {
	svg: PropTypes.node,
	as: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

BlurbInline.defaultProps = {
	svg: null,
	as: null,
};
